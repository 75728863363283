import React from 'react';
import _ from 'lodash';
import formatMessage from 'format-message';
import {
  IconVideoLine,
  IconAudioLine,
  IconImageLine,
  IconModuleLine,
  IconQuizLine,
  IconQuizSolid,
  IconDiscussionLine,
  IconDocumentLine,
  IconCommonsLine,
  IconCopyLine,
  IconAssignmentLine,
} from '@instructure/ui-icons';

import AnalyticsActions from '../../actions/analytics';
import { resourceTypes } from '../../common/app-constants';
import { Select } from '../common/select';
import SearchInput from './input';

const resource = (value, icon, options = {}) => ({
  id: value,
  label: options.text || (resourceTypes[value].pluralName),
  renderBeforeLabel: icon,
  value,
  featureFlag: options.featureFlag
});

const embeddedTypes = [
  resource('document', <IconCopyLine />),
  resource('video', <IconVideoLine />),
  resource('audio', <IconAudioLine />),
  resource('image', <IconImageLine />)
];

const newTypes = [
  resource('module', <IconModuleLine />),
  resource('quiz', <IconQuizLine />),
  resource('quizzesnext', <IconQuizSolid />, { featureFlag: 'quizzesnext' }),
  resource('assignment', <IconAssignmentLine />),
  resource('discussion_topic', <IconDiscussionLine />),
  resource('page', <IconDocumentLine />)

];

const getAllResourceTypes = (flags, acceptedCanvasResourceTypes) => {
  return newTypes
    .filter(type => !type.featureFlag || flags[type.featureFlag])
    .concat(embeddedTypes)
    .filter(type => acceptedCanvasResourceTypes.includes(type.value));
};

export default class FavoriteResourcesFilter extends React.Component {
  constructor (props) {
    super(props);
    this.throttleUpdateQuery = _.debounce(this.updateQuery, 400);
    this.currentTypes = props.flags.favorites_tray
      ? getAllResourceTypes(props.flags, props.acceptedCanvasResourceTypes)
      : embeddedTypes;
    this.currentAllTypeValues = this.currentTypes.map(type => type.value);
    this.showTypeSelector = this.currentTypes.length > 1 || !props.flags.favorites_tray;

    const embeddableTypesRequested = !props.flags.favorites_tray ||
      _.xor(embeddedTypes.map(type => type.value), props.acceptedCanvasResourceTypes).length === 0;
    this.allTypes = [resource('all',
      <IconCommonsLine />,
      { text: embeddableTypesRequested ? formatMessage('All Embeddable Types') : formatMessage('All Types') })];
  }

  componentDidMount = () => {
    this.props.onChange({ types: this.currentAllTypeValues });
  }

  handleQueryChange = event => {
    this.throttleUpdateQuery(event.target.value);
  }

  updateQuery = query => {
    this.props.onChange({ query });
    AnalyticsActions.newSearchedWord(query);
  }

  handleFilterByChange = (event, option) => {
    this.props.onChange({
      types: option.value === 'all' ? this.currentAllTypeValues : option.value
    });
  }

  render () {
    const options = this.allTypes.concat(this.currentTypes);

    return (
      <div className="SearchOptions--Favorite-resource-filter" role="banner">
        {this.showTypeSelector &&
          (<div className="SearchOptions--filter-container">
            <Select
              name="filterSelect"
              className="SearchOptions--filter-select"
              aria-label={formatMessage('Filter results by')}
              assistiveText={formatMessage('Filter results by')}
              onChange={this.handleFilterByChange}
              renderLabel={false}
              visibleOptionsCount={12}
              options={options}
            />
          </div>)}
        <SearchInput
          onChange={this.handleQueryChange}
          defaultValue={this.props.query}
          placeholder={this.props.queryPlaceholder}
          aria-label={this.props.queryAriaLabel}
          classNames="SearchInput--Favorite-resource-filter"
        />
      </div>
    );
  }
}
