import React from 'react';
import formatMessage from 'format-message';
import { importIntoCanvas } from '../../common/canvas-import/import-into-canvas';
import {
  notifyCanvasWithPostMessage,
  canvasNotificationData
} from '../../common/canvas-postmessage-data';
import { compose } from 'redux';
import withFavoriteResourceImportStatusHandler from './favorite-resource-import-status-handler';
import withFavoriteResourceImportTargetHandler from './favorite-resource-import-target-handler';
import { createNewModule } from '../../common/canvas-import/module';
import { createNewAssignmentGroup } from '../../common/canvas-import/assignment-group';

function withFavoriteResourceImportHandler (WrappedComponent) {
  return class FavoriteResourceImportHandler extends React.Component {
    state = {
      isImportTargetCreationInProgress: false,
      isAnotherImportStartBlocked: false
    };

    createImportTarget = async (courseId, canvasResourceType) => {
      const { addItemToImportTargets } = this.props;
      try {
        switch (canvasResourceType) {
          case 'module': {
            const { id, name } = await createNewModule(
              courseId,
              formatMessage('Imported Module')
            );
            addItemToImportTargets(id, name, false, true);
            this.processQueue(id);

            break;
          }
          case 'assignment': {
            const { id, name } = await createNewAssignmentGroup(
              courseId,
              formatMessage('Imported Assignments')
            );
            addItemToImportTargets(id, name, false, true);
            this.processQueue(id);
            break;
          }
          default:
        }
        this.setState({
          isImportTargetCreationInProgress: false
        });
      } catch (error) {
        this.setErrorForQueuedResources(error);
        this.clearQueuedImports();
        this.setState({
          isImportTargetCreationInProgress: false
        });
      }
    };

    startWorkOnImport = (canvasUrl, courseId, canvasResourceType, resource, courseName = 'Destination Course') => {
      const {
        setImportInProgressForResource,
        setImportErrorForResource,
        setImportFinishedForResource,
        session
      } = this.props;
      const { id, type } = resource;

      const isMultipleImportAllowed = !session.isLaunchedFromRCE;

      setImportInProgressForResource(resource);

      if (!isMultipleImportAllowed) {
        this.setState({
          isAnotherImportStartBlocked: true
        });
      }

      return async targetId => {
        const target = targetId ? {
          type: canvasResourceType,
          id: targetId
        } : {
          type: 'rce'
        };
        try {
          const { importedUrl } = await importIntoCanvas({
            canvasUrl,
            courseId,
            courseName,
            learningObject: {
              id,
              type
            },
            target
          });

          if (isMultipleImportAllowed) {
            notifyCanvasWithPostMessage(
              canvasNotificationData(courseId, resource, importedUrl)
            );
          }

          setImportFinishedForResource(importedUrl || 'success', resource, () => {
            if (!isMultipleImportAllowed) {
              document.forms[`passback-${id}`].submit();
            }
          });
        } catch (error) {
          console.error(error);
          if (!isMultipleImportAllowed) {
            this.setState({
              isAnotherImportStartBlocked: false
            });
          }
          setImportErrorForResource(error, resource);
        }
      };
    };

    queuedImports = [];
    clearQueuedImports = () => {
      this.queuedImports = [];
    };

    setErrorForQueuedResources = error => {
      const { setImportErrorForResource } = this.props;
      this.queuedImports.forEach(({ resource }) =>
        setImportErrorForResource(error, resource)
      );
    };

    processQueue = targetId => {
      this.queuedImports.forEach(({ workFunction }) => workFunction(targetId));
      this.clearQueuedImports();
    };

    queueWork = (resource, workFunction) => {
      this.queuedImports.push({ resource, workFunction });
    };

    startImport = (
      canvasUrl,
      courseId,
      canvasResourceType,
      courseName
    ) => async resource => {
      const { currentImportTarget, setImportErrorForResource } = this.props;
      try {
        const { isImportTargetCreationInProgress, isAnotherImportStartBlocked } = this.state;

        if (isAnotherImportStartBlocked) {
          return;
        }

        if (currentImportTarget && currentImportTarget.value === '_new') {
          this.queueWork(
            resource,
            this.startWorkOnImport(
              canvasUrl,
              courseId,
              canvasResourceType,
              resource,
              courseName
            )
          );
          if (!isImportTargetCreationInProgress) {
            this.setState({
              isImportTargetCreationInProgress: true
            });
            this.createImportTarget(courseId, canvasResourceType);
          }
        } else {
          return this.startWorkOnImport(
            canvasUrl,
            courseId,
            canvasResourceType,
            resource,
            courseName
          )(currentImportTarget && currentImportTarget.value);
        }
      } catch (exception) {
        setImportErrorForResource(exception, resource);
      }
    }

    render () {
      return (
        <WrappedComponent startImport={this.startImport} {...this.props} />
      );
    }
  };
}
export default compose(
  withFavoriteResourceImportStatusHandler,
  withFavoriteResourceImportTargetHandler,
  withFavoriteResourceImportHandler
);
