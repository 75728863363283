import Uniflow from 'uniflow';
import SearchActions from '../actions/search';

let bootstrapped = false;

const SearchStore = Uniflow.createStore({
  state: {
    results: [],
    searchPending: false,
    searchError: null,
    searchSuccessful: false,
    cursor: null,
    lastQuery: {},
    count: -1
  },

  bootstrap () {
    if (bootstrapped) {
      return;
    }

    // pending
    SearchActions.on('search-pending', (query) => {
      SearchStore.setState({
        searchPending: true,
        searchError: null,
        searchSuccessful: false,
        cursor: null,
        lastQuery: query
      });
    });

    // error
    SearchActions.on('search-error', (error) => {
      SearchStore.setState({
        searchPending: false,
        searchError: error
      });
    });

    // success
    SearchActions.on('search-successful', (results) => {
      const learningObjects = (results && Array.isArray(results.items)) ? results.items : [];
      const { cursor, count } = results && results.meta;

      results = SearchStore.state.results.slice();

      const isNewSearch = SearchStore.state.lastQuery.where.cursor === 'initial';
      if (isNewSearch) {
        results = learningObjects;
      } else {
        results = results.concat(learningObjects);
      }

      SearchStore.setState({
        searchPending: false,
        searchSuccessful: true,
        results,
        cursor,
        count
      });
    });

    bootstrapped = true;
  },

  hasMore () {
    return !!(
      this.state.cursor &&
      this.state.results.length < this.state.count
    );
  }
});

export default SearchStore;
